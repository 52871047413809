.services{
    background-color: #04395F;
    padding: 20px;
    @media screen and (max-width: 454px) {
        padding: 10px;
    }

    .pricing, .firstP{
        color: #E7EEF8;
        line-height: 35px;
    }

    .banner{
        background: linear-gradient(#235093, #1376BD);

        .rightBorder{
            border-right: 1px solid #04395F;

            @media screen and (max-width: 454px) {
                border: none;
            }
            
            .limited{
                background-color: #F2761C;
                border-radius: 20px;
                max-width: max-content;
                padding: 6px 20px;
            }
        }

        .welcome{
            color: #E7EEF8;
            line-height: 32px;
            margin-bottom: 10px;
        }
        .btn{
            line-height: 24px;
            background-color: #04395F;
            color: #ffffff;
            padding: 8px 30px;
            border-radius: 4px;
            outline: none;
            border: none;
            text-decoration: none;
        }

    }

    .cards{
        background-color: #E7EEF8;
        height: 346px !important;
        width: 411px;
        border-radius: 14px;

        @media screen and (max-width: 1280px) {
            width: 100%;
            height: 100% !important;
        }
        @media screen and (max-width: 545px) {
            height: max-content !important;
        }

        .cardHeader{
            border-color: #548ED1;
            background-color: transparent;
            padding-bottom: 16px;
            .cardHeading{
                color: #04395F;
                line-height: 32px;
            }
        }
        .body{
            width: 80%;

            @media screen and (max-width: 1280px) {
                width: 100%;
                padding-bottom: 0 !important;
            }

        }
        .dataHeading{
            color: #04395F;
        }
        .cardFirstP{
            color: #04395F;
            line-height: 24px;
        }
        .cardSecondP{
            color: #04395F;
            line-height: 24px;

            .priceText{
                letter-spacing: -0.3px;
                font-weight: 400;
            }

            .strike{
                text-decoration: line-through;
            }

            .newPrice{
                color: #F2761C;
            }
        }

        .coupon{
            border: 1px dashed #084571;
            border-radius: 4px;
            max-width: max-content;
            padding: 0 12px;

            @media screen and (max-width: 545px) {
                margin: 22px 0;
            }

            p{
                color: #084571;
                line-height: 32px;
                font-size: 15px;
                margin-bottom: 0;


                span{
                    color: #F2761C;
                    line-height: 32px;
                    letter-spacing: 11%;
                    text-align: center;
                }

            }
        }

    }

    



}

.kick{
    background-color: #FFF9F4;
    
    .kickP, .kickH3{
        color: #084571;
        line-height: 51px;
    }
}