.yourWay {
  background-color: #fff9f4;
  padding: 118px 0px 80px;

  .content {
    width: 46%;
    color: #084571;

    h4 {
      margin-top: 20px;
      margin-bottom: 36px;
    }

    p {
      max-width: 500px;
      line-height: 36px;
    }

    .firstP {
      max-width: 440px;
    }

    .secondP {
      max-width: 336px;
      margin-top: 36px;
    }

    a {
      line-height: 20px;
      padding: 24px 62px;
      border-radius: 5px;
      border: none;
      background-color: #548ed1;
      color: #fff;
      margin-top: 42px;
      text-decoration: none;
      display: inline-block;
    }
  }
  .points {
    width: 54%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 42px;
    row-gap: 52px;

    .cell {
      background-color: #084571;
      border: 2px solid #174a97;
      padding: 68px 32px 26px;
      color: #fff;
      border-radius: 12px;

      .iconWrpr {
        position: absolute;
        left: 32px;
        top: 0px;
        transform: translateY(-36%);
        background-color: #3879b7;
        height: 86px;
        width: 86px;
        border-radius: 10px;
      }

      h5 {
        line-height: 32px;
        margin-bottom: 15px;
      }

      p {
        line-height: 28px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .yourWay {
    padding: 50px 0px 30px;
    .contentWrpr {
      flex-flow: column;

      .content {
        width: 100%;

        h4 {
          font-size: 28px;
          text-align: center;
        }

        p {
          text-align: center;
          font-size: 16px;
          line-height: 28px;
          max-width: 310px;
          margin: 0px auto;
        }
        .firstP {
        }

        .secondP {
          margin-top: 24px;
        }

        a {
          margin: 24px 26px 0px;
          font-size: 14px;
          padding: 14px 50px;
          width: calc(100% - 48px);
          text-align: center;
        }
      }

      .points {
        width: 100%;
        grid-template-columns: 1fr;
        row-gap: 64px;
        padding: 60px 24px 0px;

        .iconWrpr {
          left: 50%;
          transform: translate(-50%, -46%) scale(0.8);
        }

        h5 {
          text-align: center;
          font-size: 18px;
        }

        p {
          text-align: center;
          font-size: 13px;
          line-height: 24px;
        }
      }
    }
  }
}
