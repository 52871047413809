.left{
    background: linear-gradient(to right, #3170CF, #193969);
    border-radius: 10px 0 0 10px ;
    @media screen and (max-width: 454px) {
        border-radius: 0;
    }

    .save{
        font-style: 42px;
        line-height: 50.83px;
        color: #ffffff;
        margin-bottom: 0;
    }
    .monthly{
        line-height: 26.63px;
        color: #F5F5F5;

        span{
            line-height: 14.52px;
            color: #FFFFFF;
        }
    }
}

.right{
    background: #04395F;
    border-radius: 0 10px 10px 0;
    @media screen and (max-width: 454px) {
        border-radius: 0;
    }

    .dont{
        line-height: 21.78px;
        color: #D6E6F7;
    }
    .price{
        color: #F2761C;
        line-height: 33.89px;
    }
    .shipping{
        color: #E7EEF8;
        line-height: 19.36px;
    }
    .btn{
        color: #ffffff;
        background-color: #3170CF;
        padding: 12px 36px;
        border: none;
        outline: none;
        border-radius: 9px;
        text-decoration: none;

    }
}