.pointsSec {
  padding: 100px 0px 64px;

  .points {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 16px;

    .cell {
      background-color: #084571;
      border: 2px solid #174a97;
      padding: 86px 18px 44px;
      color: #fff;
      border-radius: 12px;

      .iconWrpr {
        position: absolute;
        left: 18px;
        top: 0px;
        transform: translateY(-36%);
        background-color: #3879b7;
        height: 86px;
        width: 86px;
        border-radius: 10px;
      }

      h5 {
        line-height: 32px;
        margin-bottom: 22px;
      }

      p {
        line-height: 32px;
      }
    }
  }
}

.ftr {
  padding: 44px 0px;
}

@media screen and (max-width: 960px) {
  .pointsSec {
    padding: 100px 0px 64px;

    .points {
      grid-template-columns: 1fr;
      row-gap: 50px;
    }
  }
  .ftr {
    padding: 13px 40px;
    font-size: 14px;
  }
}
