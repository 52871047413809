.yourCall {
  background-color: #04395f;
  padding: 88px 0px 64px;

  h5 {
    line-height: 26px;
    color: #fff;
  }

  p {
    max-width: 1056px;
    line-height: 32px;
    margin: 24px auto 36px;
    color: #fff;
  }

  a {
    margin: 0px auto;
    display: block;
    border: none;
    border-radius: 5px;
    padding: 24px 60px;
    color: #fff;
    background-color: #548ed1;
    display: inline-block;
    text-decoration: none;
  }
}

@media screen and (max-width: 960px) {
  .yourCall {
    padding: 48px 0px 56px;
    h5 {
      font-size: 26px;
      text-align: center;
    }

    p {
      font-size: 16px;
      line-height: 26px;
      max-width: 300px;
      margin: 24px auto 46px;
    }

    a {
      font-size: 14px;
      padding: 14px 50px;
      text-align: center;
      margin: 0px auto;
    }
  }
}
