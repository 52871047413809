@import "./mixin.scss";

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

// end reset.css

html,
body,
#root,
.App {
  height: 100%;
}

.App {
  overflow: auto;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  position: relative;
  outline: none;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("./assets/fonts/Bebas-Neue/BebasNeue-Regular.ttf");
}

.ffbn {
  font-family: "Bebas Neue";
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf");
}

.ffi {
  font-family: "Inter";
}

@font-face {
  font-family: "mont";
  src: url("./assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}

.ffm {
  font-family: "mont";
}

@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
}

.ffp{
  font-family: "poppins";
}

@font-face {
  font-family: "circular";
  src: url("./assets/fonts/Circular-Std-Font/circular-std-medium-500.ttf");
}

.ffct{
  font-family: "circular";
}

.fw900 {
  font-weight: 900;
}

.fw800 {
  font-weight: 800;
}

.fw700 {
  font-weight: 700;
}

.fw600 {
  font-weight: 600;
}

.fw500 {
  font-weight: 500;
}

.fw450 {
  font-weight: 450;
}
.fw400{
  font-weight: 400;
}

.tac {
  text-align: center;
}

.ttuc {
  text-transform: uppercase;
}

.fs96 {
  font-size: 96px;
}

.fs48 {
  font-size: 48px;
}

.fs40 {
  font-size: 40px;
}

.fs38 {
  font-size: 38px;
}

.fs36 {
  font-size: 36px;
}

.fs35 {
  font-size: 35px;
}

.fs34 {
  font-size: 34px;
}

.fs32 {
  font-size: 32px;
}

.fs30 {
  font-size: 30px;
}

.fs28 {
  font-size: 28px;
}

.fs26 {
  font-size: 26px;
}

.fs24 {
  font-size: 24px;
}

.fs22 {
  font-size: 22px;
}

.fs20 {
  font-size: 20px;
}

.fs17 {
  font-size: 17px;
}

.fs18 {
  font-size: 18px;
}

.fs16 {
  font-size: 16px;
}

.fs15 {
  font-size: 15px;
}

.fs14 {
  font-size: 14px;
}
.fs12{
  font-size: 12px;
}

.content-wrpr {
  width: 100%;
  // max-width: 1406px;
  max-width: 1280px;
  padding: 0px 20px;
  margin: 0px auto;
}

.df {
  display: flex;

  &.ffc {
    flex-flow: column;
  }

  &.aic {
    align-items: center;
  }

  &.jcc {
    justify-content: center;
  }

  &.acc {
    align-items: center;
    justify-content: center;
  }

  .mla {
    margin-left: auto;
  }
}

.swiper-wrapper {
  padding-bottom: 98px;
  display: grid !important;
  grid-auto-columns: minmax(100, 1fr);
  grid-auto-flow: column;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #084571;
  height: 22px;
  width: 22px;
}

.swiper-pagination-bullet {
  height: 22px !important;
  width: 22px !important;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  // bottom: -76px;
}

.c7E8DA8 {
  color: #7e8da8;
}

.c084571 {
  color: #084571;
}

.c3D4B5C {
  color: #3d4b5c;
}

.c1E6196 {
  color: #1e6196;
}

.c344054 {
  color: #344054;
}

.c18375E {
  color: #18375e;
}

.c04395F {
  color: #04395f;
}

.cfff {
  color: #fff;
}

.c4377B3 {
  color: #4377b3;
}

.cD4E6FA {
  color: #d4e6fa;
}

.c4377B3 {
  color: #4377b3;
}

.bc084571 {
  background-color: #084571;
}

.bc1E6196 {
  background-color: #1e6196;
}

.bc145F95 {
  background-color: #145f95;
}

.bc548ED1 {
  background-color: #548ed1;
}

.bc126094 {
  background-color: #126094;
}

.bcE7EEF8 {
  background-color: #e7eef8;
}

.bcD4E6FA {
  background-color: #d4e6fa;
}

.bcFFF9F4 {
  background-color: #fff9f4;
}
