.portal {
  padding-top: 34px;

  h4 {
    margin-bottom: 26px;
    line-height: 32px;
  }

  .itemsList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0px 50px;
    column-gap: 20px;
    margin-top: 52px;

    .sec {
      padding: 36px 24px;
      border-radius: 5px;

      h5 {
        line-height: 32px;
        margin-bottom: 20px;
      }

      p {
        line-height: 32px;
      }
    }
  }

  .acc {
    box-shadow: 1px 5px 23px 4px #9bb8d95e;
    margin: 114px 50px 0px;

    .list {
      border-right: 1px solid #4377b3;
      padding: 40px 32px 40px 26px;

      .hdr {
        margin-bottom: 50px;
      }

      .op {
        height: 54px;
        white-space: nowrap;
        cursor: pointer;
        border-radius: 6px;
        padding: 0px 24px 0px 8px;
        background-color: #fff;
        transition: background-color 0.2s;

        .svgWrapper {
          height: 40px;
          width: 40px;
        }

        .title {
          display: block;
        }

        &.selected {
          background-color: #084571;
          color: #fff;

          svg path {
            stroke: white;
          }
        }
      }
    }
    .items {
      padding: 40px 56px;

      .secHdr {
        line-height: 30px;
      }

      .subHeader {
        margin: 16px 0px 0px;
        font-style: italic;
        line-height: 24px;
      }

      .itemUl {
        list-style: disc;
        padding: 0px 30px;
        line-height: 32px;
        margin-top: 22px;

        li {
          margin-bottom: 20px;
        }
      }

      .lineUl {
        list-style: circle;
        line-height: 32px;
        padding-left: 20px;

        li {
          margin-bottom: 16px;
        }
      }
    }

    .liBold {
      margin-right: 5px;
    }
  }
  .footer {
    margin-top: 40px;
    line-height: 20px;
  }

  .mobile {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .portal {
    h4 {
      font-size: 24px;
      margin-bottom: 14px;
    }

    .itemsList {
      grid-template-columns: 1fr;
      row-gap: 40px;
      padding: 0px;
      text-align: center;
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: block;
      margin: 35px 0px 0px;

      // .acc {
      .list {
        padding: 0px;
        border-right: none;
        // }

        .op {
          // border: 1px solid #164672;
          border-radius: 0px;
        }

        .items {
          padding: 20px 22px;

          .secHdr {
            font-size: 16px;
            line-height: 28px;
          }

          .subHeader {
            font-size: 14px;
            line-height: 22px;
            margin-top: 8px;
          }

          p {
            font-size: 0.95em;
          }

          span {
            font-size: 0.95em;
          }

          li {
            font-size: 0.95em;
          }
        }
      }
    }
  }
}
