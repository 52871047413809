.pLanding {
  background-color: #145f95;
  color: #fff;
  padding: 76px 0px 98px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    border-radius: 0px 0px 5px 0px;
    bottom: -20px;
    border: 51px solid #145f95;
    z-index: 1;
    transform: translate(-50%, 20px) rotate(45deg);
  }

  .first {
    margin-top: 42px;
    line-height: 50px;
  }

  .second {
    line-height: 40px;
  }

  .third {
    line-height: 40px;
  }

  a {
    background-color: #548ed1;
    line-height: 20px;
    color: #fff;
    padding: 15px 50px;
    margin-top: 110px;
    border: none;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;
  }

  .imgSec {
    width: 50%;

    .imageWrpr {
      background-image: url("../../../assets/imgs/platform-landing.png");
      height: 514px;
      width: 566px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

@media screen and (max-width: 960px) {
  .pLanding {
    padding: 54px 0px 68px;
    .contentWrpr {
      flex-flow: column;

      .content {
        display: flex;
        flex-flow: column;
        align-items: center;
      }

      h2 {
        font-size: 24px;
        text-align: center;
      }

      .first {
        text-align: center;
        font-size: 20px;
        margin-top: 22px;
      }

      .second {
        text-align: center;
        font-size: 22px;
        // margin-top: 18px;
      }

      .third {
        text-align: center;
        font-size: 22px;
        // margin-top: 18px;
      }

      a {
        margin-top: 40px;
      }

      .imgSec {
        width: 100%;
        margin-top: 50px;

        .imageWrpr {
          height: 230px;
          width: 240px;
          // width: 566px;
          // background-size: 100%;
          // background-repeat: no-repeat;
        }
      }
    }
  }
}
