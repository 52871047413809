.banner{
    background-color: #04395F;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 20px;
    // padding-bottom: 55px;
    justify-content: space-between;
    height: 100%;

    @media screen and (max-width: 454px) {
        flex-direction: column;
    }
    
    
    .imgWrpr{
        padding: 10px;
        width: 50%;
        
        img{
            object-fit: contain;
            max-width: 100%;
            max-height: 360px;
        }
    }



    .right{
        height: 100%;
        padding: 20px 80px;
        width: 50%;
        gap: 20px;

        @media screen and (max-width: 454px) {
            width: 100%;
            padding: 10px;
        }

        .heading{
            line-height: 32px;
            // letter-spacing: 3.5%;
            color: #e7eef8;
            margin: 20px 0;
        }
        .para{
            color: #e7eef8;
            line-height: 30px;
            margin-bottom: 40px;
        }
        .btn{
            color: #04395F;
            line-height: 20px;
            background-color: #D6E6F7;
            padding: 14px 22px;
            border-radius: 5px;
            margin-top: 30px;
            text-decoration: none;
        }
    }

}