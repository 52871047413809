.theAdvantage {
  padding-bottom: 174px;
  h3 {
    color: #084571;
    margin-top: 86px;
    margin-bottom: 126px;
  }
  .imgSec {
    width: 63%;

    .circleImg {
      position: absolute;
      bottom: 0px;
      left: 0px;
      transform: translate(-26%, 6%);
    }

    .mainImg {
      width: 100%;
    }
  }

  .textSec {
    padding-left: 80px;
    width: 37%;

    .line {
      margin-bottom: 60px;
      p {
        margin-left: 28px;
        color: #084571;
        max-width: 528px;
        line-height: 30px;
      }

      &:nth-last-child(1) {
        margin-bottom: 0px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .theAdvantage {
    padding-bottom: 0px;
    h3 {
      font-size: 24px;
    }

    .innerContentWrpr {
      flex-flow: column;

      .imgSec {
        width: 100%;
        .circleImg {
          width: 100%;
        }
      }

      .textSec {
        width: 100%;
        padding: 60px 20px 40px;

        .line {
          margin-bottom: 30px;

          img {
            scale: 0.7;
          }

          p {
            font-size: 14px;
            font-weight: 500;
            margin-left: 10px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
