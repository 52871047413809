.banner{
    background-color: #084571;
    border-radius: 11px;

    .leftSide{
        border-right: 1px solid #FCF0E6;

        
        .new{
            background-color: #FCF0E6;
            color: #084571;
            line-height: 28px;
            max-width: max-content;
            text-align: center;
            padding: 2px 20px;
            border-radius: 8px;
        }
        .feature{
            color: #F2761C;
            line-height: 28px;
        }
        .connecting{
            font-size: 13px;
            line-height: 28px;
            color: #FCF0E6;
        }
    }

    .discover{
        color: #FCF0E6;
        line-height: 28px;
        margin: 20px 0px;
    }
    .text{
        color: #FCF0E6;
        line-height: 28px;
        margin: 10px 0;
    }
    .upgradeBtn{
        background-color: #3170CF;
        color: #FFFCFA;
        line-height: 24px;
        outline: none;
        border: none;
        padding: 10px 20px;
        border-radius: 4px;
        text-decoration: none;
    }
    .freeBtn{
        margin: 0 10px;
        outline: none;
        border: none;
        background-color: transparent;
        color: #FFFFFF;
        line-height: 24px;
        text-decoration: none;
        margin-left: 30px;
    }
    .lineTop{
        height: 28px;
    }

    .line{
        rotate: 180deg;
        height: 28px;
    }

}