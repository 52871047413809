.onTheLabel {
  padding-top: 64px;

  .header {
    line-height: 44px;
    max-width: 1050px;
    margin: 54px auto 0px;
  }

  .list {
    max-width: 588px;
    margin: 84px auto 70px;

    .listItem {
      margin-bottom: 26px;

      p {
        margin-left: 24px;
      }
    }
  }

  a {
    display: block;
    border: 0px;
    border-radius: 5px;
    padding: 24px 62px;
    margin: 56px auto 46px;
    text-decoration: none;
  }
}

.ftr {
  padding: 44px 0px;
  margin-top: 100px;
}

@media screen and (max-width: 960px) {
  .onTheLabel {
    padding: 44px 24px 0px;

    h4 {
      font-size: 18px;
    }

    .header {
      line-height: 34px;
      font-size: 16px;
      margin: 54px auto 0px;
    }

    .list {
      .listItem {
        img {
          scale: 0.6;
        }

        p {
          font-size: 16px;
          margin-left: 6px;
        }
      }
    }

    .lastText {
      font-size: 22px;
      line-height: 36px;
    }

    a {
      margin: 26px auto 26px;
    }
  }
  .ftr {
    padding: 13px 40px;
    font-size: 14px;
  }
}
