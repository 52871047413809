.faqs {
  padding-top: 120px;

  .accordionA {
    max-width: 90%;
    .blockLine {
      display: block;
    }

    ul.primary {
      list-style: disc;
      // list-style-position: unset;
      padding-left: 24px;

      li.primary {
        margin-bottom: 20px;
        line-height: 30px;

        ul.secondary {
          list-style: square;
          padding: 10px 0px 10px 24px;

          li.secondary {
            padding-bottom: 16px;

            ul.third {
              padding-left: 24px;
              list-style: circle;
              padding-top: 10px;

              li.third {
                padding-bottom: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .accordionA {
    font-size: 16px;
  }
}
