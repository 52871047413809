.weOffer {
  padding-top: 74px;
  padding-bottom: 86px;

  .content {
    z-index: 1;
    box-shadow: 1px 5px 23px 4px rgba(155, 184, 217, 0.369);
    padding: 20px;
    padding: 112px 154px;
    line-height: 32px;
    background-color: #fff;

    .title {
      margin-bottom: 6px;
    }
    .subTitle {
      margin-bottom: 78px;
    }
    .hdrP {
    }
    .descP {
      margin-bottom: 42px;
      max-width: 962px;
    }
  }
  a {
    display: block;
    border: none;
    border-radius: 4px;
    padding: 24px 62px;
    color: #fff;
    margin: 40px auto 0px;
    z-index: 1;
    text-decoration: none;
  }

  .bg {
    background-color: #084571;
    height: 710px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
}

@media screen and (max-width: 960px) {
  .weOffer {
    padding-bottom: 46px;
    .content {
      padding: 32px 28px;

      .title {
        font-size: 20px;
      }

      .subTitle {
        font-size: 18px;
        line-height: 28px;
      }

      .hdrP {
        font-size: 17px;
      }

      .descP {
        font-size: 16px;
      }
    }
  }
}
