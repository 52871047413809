.landing {
  // padding-top: 110px;
  padding-top: 50px;
  background-color: #E7EEF8;

  .landingSection {
    padding-bottom: 124px;

    .imgWrpr {
      width: 54%;

      img {
        max-width: 100%;
      }
    }

    h5 {
      margin-bottom: 12px;
    }

    .firstP {
      max-width: 550px;
      line-height: 38px;
      margin-top: 46px;
    }

    .secondP {
      margin-top: 24px;
    }

    .thirdP {
      line-height: 32px;
      max-width: 550px;
      margin-top: 52px;
    }
  }

  .subHeader {
    height: 62px;
    max-width: 588px;
    width: 90%;
    padding: 0px 20px;
    margin-top: 46px;
  }

  .createAcc {
    line-height: 32px;
    max-width: 999px;
    margin: 64px 0px 88px;
    line-height: 32px;
  }

  h4 {
    margin-bottom: 48px;
  }

  h5 {
    max-width: 999px;
    line-height: 38px;
    margin-bottom: 94px;
  }

  .bar {
    width: 100%;
    padding: 37px 0px;

    img {
      position: absolute;
      right: 10%;
      top: 20px;
      z-index: 1;
    }
  }
}

.info {
  .contentWrpr {
    padding-bottom: 82px;
    border-bottom: 1px solid #4377b3;
  }
  .toSec {
    .a {
      padding-top: 74px;
    }
    .b {
      margin-top: 25px;
    }

    a {
      margin-top: 62px;
      padding: 24px 62px;
      border: none;
      border-radius: 5px;
      display: inline-block;
      text-decoration: none;
    }
  }
}

.pb {
  padding: 44px 0px 146px;
  h5 {
  }
  p {
    max-width: 560px;
    line-height: 44px;
  }

  .ast {
    margin-top: 46px;
  }
}

.banner {
  position: absolute;
  z-index: 1;
  width: calc(100% - 40px);
  transform: translateY(-38%);
  line-height: 35px;
  padding: 46px 32px 32px;
  margin: 0px 20px;
  left: 0px;

  p:nth-child(1) {
    margin-bottom: 24px;
  }
}

.priceList {
  padding-top: 190px;

  .priceListInnerWrpr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 72px;
    row-gap: 54px;
    padding-left: 14px;

    .sec {
      background-color: #084571;
      padding-top: 122px;

      .secHeader {
        position: absolute;
        left: -14px;
        top: 26px;
        background-color: #04395f;
        padding: 12px 30px;
        border-radius: 1px;
        box-shadow: 2px 4px 0px 0px #30527e;

        .imgWrpr {
          height: 38px;
          width: 38px;
          margin-right: 16px;
        }
      }

      .expText {
        max-width: 368px;
        line-height: 32px;
        padding-left: 44px;
        height: 114px;
      }

      .priceSec {
        background-color: #d4e6fa;
        margin: 0px 24px 24px 24px;
        height: 86px;
        padding: 9px 14px;

        p {
          line-height: 32px;
        }
      }
    }
  }

  // .line {
  //   margin-bottom: 78px;

  //   .header {
  //     line-height: 32px;
  //     margin-bottom: 10px;

  //     p {
  //       max-width: 658px;
  //     }

  //     .icon {
  //       width: 52px;
  //       margin-right: 46px;
  //     }
  //   }

  //   .content {
  //     margin-left: 98px;
  //     padding: 12px 28px;
  //     max-width: 658px;
  //     width: calc(100% - 98px);
  //     line-height: 32px;
  //   }
  // }
}

.needSomething {
  padding: 62px 30px 25px;

  p {
    max-width: 950px;
    margin: 0px auto;
    line-height: 60px;
  }

  .mb {
    margin-bottom: 30px;
  }

  a {
    display: block;
    margin: 40px auto 62px;
    padding: 24px 62px;
    border: none;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;
  }
}

.footerBar {
  line-height: 28px;
  padding: 44px 80px;
}

@media screen and (max-width: 960px) {
  .landing {
    padding-top: 64px;

    .landingSection {
      flex-flow: column;
      flex-direction: column-reverse;

      .imgWrpr {
        width: 100%;
        margin-top: 16px;
      }

      h5 {
        font-size: 13px;
        text-align: center;
      }

      h2 {
        font-size: 18px;
        text-align: center;
      }

      .firstP {
        line-height: 34px;
        font-size: 18px;
        text-align: center;
        margin: 32px auto 10px;
      }

      .secondP {
        line-height: 34px;
        font-size: 20px;
        text-align: center;
        margin: 0px auto;
      }

      .thirdP {
        line-height: 28px;
        font-size: 15px;
        text-align: center;
        margin: 14px auto;
        max-width: 300px;
      }
    }

    .bar {
      padding: 20px 0px;
      div {
        font-size: 18px;

        img {
          height: 110px;
          right: 4%;
          top: 27px;
        }
      }
    }

    .banner {
      p:nth-child(1) {
        font-size: 18px;
        padding: 0px 48px;
      }

      p:nth-child(2) {
        font-size: 16px;
        line-height: 32px;
        // padding: 0px 48px;
      }
    }

    .info {
      .contentWrpr {
        padding-bottom: 54px;
        .a {
          font-size: 16px;
        }

        .b {
          font-size: 20px;
        }

        a {
          margin-top: 28px;
        }
      }
    }

    .pb {
      .contentWrpr {
        flex-flow: column;

        .imgWrpr {
          max-width: 100%;

          img {
            max-width: 100%;
          }
        }
      }
    }

    .priceList {
      .priceListInnerWrpr {
        grid-template-columns: 1fr;
        padding-left: 0px;

        .sec {
          .expText {
            font-size: 16px;
          }

          .priceSec {
            height: initial;
          }
        }
      }
    }

    .needSomething {
      p {
        font-size: 19px;
        line-height: 36px;
      }
    }

    .footerBar {
      padding: 13px 40px;
      font-size: 14px;
    }
  }
}
