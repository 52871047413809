.easy {
  background-color: #f4f7ff;
  padding: 66px 0px 76px;

  h5 {
    line-height: 42px;
    color: #084571;
    letter-spacing: 1.5px;
  }

  .list {
    justify-content: space-around;
    margin-top: 62px;
    padding: 0px 50px;

    .sec {
      .iconWrpr {
        background-color: #3879b7;
        height: 60px;
        width: 60px;
        border-radius: 13px;
        margin-right: 20px;
      }

      p {
        color: #084571;
      }
    }
  }
}

.filterBy {
  background-color: #fff;
  padding-top: 78px;
  h5 {
    letter-spacing: 1.5px;
  }

  img {
    margin: 0px auto;
    display: block;
    max-width: 100%;
    z-index: 1;
    margin-top: 78px;
  }

  a {
    padding: 24px 64px;
    border: none;
    border-radius: 5px;
    line-height: 20px;
    margin: 64px auto 109px;
    display: inline-block;
    text-decoration: none;
  }
}

.bar {
  line-height: 28px;
  padding: 44px 80px;
}

@media screen and (max-width: 960px) {
  .easy {
    padding: 46px 0px 56px;

    h5 {
      font-size: 18px;
      max-width: 320px;
      margin: 0px auto;
      line-height: 34px;
    }

    .list {
      flex-flow: column;

      .sec {
        flex-flow: column;
        margin-bottom: 40px;

        .iconWrpr {
          margin-right: 0px;
          scale: 0.7;
        }
        p {
          text-align: center;
          white-space: nowrap;
          margin-top: 10px;
          font-size: 16px;
        }
      }
    }
  }
  .filterBy {
    h5 {
      font-size: 18px;
    }
  }

  .bar {
    font-size: 14px;
    padding: 13px 40px;
  }
}
