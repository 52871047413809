.accordion {
  box-shadow: 1px 5px 23px 4px #9bb8d95e;

  p {
    padding: 42px 0px;
    border-bottom: 1px solid #bcbcbc;
  }

  .line {
    border-bottom: 1px solid #bcbcbc;

    &:nth-last-child(1) {
      border-bottom: none;
    }

    .q {
      padding: 38px 60px;
      cursor: pointer;

      img {
        rotate: 0deg;
        transition: rotate 0.2s;
        &.rotate {
          rotate: 180deg;
        }
      }
    }

    .a {
      // border-top: 1px solid transparentize($color: #000000, $amount: 0);
      padding: 4px 60px 38px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 960px) {
  .accordion {
    p {
      padding: 24px 0px;
      font-size: 18px;
    }

    .line {
      .q {
        padding: 20px 28px;
        line-height: 28px;
        font-size: 16px;

        .openerWrpr {
          padding-left: 14px;
        }
      }

      .a {
        padding: 4px 30px 38px;
        font-size: 16px;
        li {
          font-size: 16px;
        }
      }
    }
  }
}
