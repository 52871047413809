.popup {
  //   position: fixed;
  // height: calc(100% - 86px);
  width: 100%;
  background-color: #f6f9fc;
  // z-index: 99;
  padding: 40px;

  .pageTitle {
    margin: 0px auto;
    width: 100%;
    max-width: 1246px;
    padding: 30px 0px 50px;
  }
  .content {
    margin: 0px auto;
    width: 100%;
    max-width: 1246px;
    background-color: #fff;
    // height: 100%;
    width: 100%;
    // color: red;
    // overflow: auto;
    padding: 78px;

    .title {
      color: #3d4b5c;
      font-size: 20px;
      font-weight: 800;
      font-family: "mont";
      margin-bottom: 20px;
    }

    .subsitle {
      color: #3d4b5c;
    }

    .text {
      color: #3d4b5c;
      font-family: "mont";
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 36px;
      line-height: 34px;

      &.underlined {
        text-decoration: underline;
        font-weight: 700;
      }
    }

    .ulHeader {
      color: #3d4b5c;
      font-family: "mont";
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 34px;
    }

    .numbers {
      list-style: decimal;
      color: #3d4b5c;
      font-family: "mont";
      font-weight: 600;
      font-size: 16px;
      padding-left: 30px;
      line-height: 34px;

      li {
        margin-bottom: 12px;
      }
    }
    .bullets {
      list-style: disc;
      color: #3d4b5c;
      font-family: "mont";
      font-weight: 600;
      font-size: 16px;
      padding-left: 30px;
      line-height: 34px;

      li {
        margin-bottom: 12px;
      }
    }
  }
  .abc {
    list-style: lower-alpha;
    color: #3d4b5c;
    font-family: "mont";
    font-weight: 600;
    font-size: 16px;
    padding-left: 30px;
    line-height: 34px;

    li {
      margin-bottom: 12px;
    }
  }
}

@media screen and (max-width: 960px) {
  .popup {
    //   position: fixed;
    // height: calc(100% - 86px);
    width: 100%;
    background-color: #f6f9fc;
    // z-index: 99;
    padding: 40px;

    .pageTitle {
      font-size: 20px;
      text-align: center;
    }

    .content {
      padding: 20px 20px;
    }
  }
}
