.footer {
  background-color: #084571;
  padding-top: 86px;
  padding: 86px 0px 70px;
  color: #fff;

  .contentWrpr {
    padding: 0px 130px;
  }

  .footerSec {
    // background-color: aquamarine;
    padding-right: 116px;
    color: #fff;

    a {
      color: #fff;
      display: block;
      text-decoration: none;
      line-height: 32px;
    }

    h5 {
      line-height: 18px;
      margin-bottom: 14px;
    }

    img {
      margin-top: 12px;
    }
  }

  .divider {
    background-color: #fcf0e6;
    height: 2px;
    width: 60px;
    margin-top: 30px;
  }

  .contactPSec {
    line-height: 22px;
    margin-top: 20px;
  }
}

.copy {
  background-color: #103e60;
  padding: 14px 130px 14px;
  color: #fff;

  span {
    color: #bebebe;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding-top: 40px;
    .contentWrpr {
      padding: 0px 32px;

      .innerContentWrpr {
        flex-flow: column;
      }

      .footerSec {
        margin-bottom: 40px;
      }

      .logoSec {
        margin-left: 0px;
        margin-top: 50px;

        img {
          height: 26px;
        }

        .divider {
          margin-top: 14px;
        }
      }
    }
  }

  .copy {
    white-space: nowrap;
    padding: 14px 32px 14px;

    div {
      font-size: 12px;
    }
  }
}
