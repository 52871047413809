.yourCall {
  background-color: #fff9f4;
  padding-top: 250px;
  padding-bottom: 66px;
  color: #084571;

  h3 {
    line-height: 32px;
  }

  p {
    max-width: 1056px;
    line-height: 32px;
    margin: 24px auto 38px;
  }

  a {
    background-color: #084571;
    margin: 0px auto;
    display: block;
    line-height: 20px;
    padding: 24px 62px;
    border-radius: 5px;
    color: #fff;
    border: none;
    display: inline-block;
    text-decoration: none;
  }
}

@media screen and (max-width: 960px) {
  .yourCall {
    padding-top: 186px;

    h3 {
      font-size: 22px;
    }

    p {
      font-size: 16px;
      line-height: 26px;
    }
  }
}
