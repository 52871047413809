.easy {
  color: #04395f;
  z-index: 1;

  .easyCard {
    background-color: #f4f7ff;
    box-shadow: -6px 16px 48px 2px rgba(30, 51, 83, 0.18);
    transform: translateY(-60px);
    border-radius: 6px;
    padding: 0px 80px;

    h5,
    h3 {
      line-height: 42px;
      color: #084571;
      padding-top: 32px;
    }

    .stepsWrpr {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 94px;
      margin-top: 46px;
      padding-bottom: 80px;

      .stepWrpr {
        .icon {
          height: 64px;
          width: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .cntnt {
          margin-top: 78px;
          line-height: 32px;
          color: #084571;
          .sub {
            font-size: 0.8em;
          }
        }

        &:after {
          content: "";
          height: 82%;
          width: 1px;
          background-color: #a3d4ff;
          position: absolute;
          right: calc(-93px / 2);
          bottom: 0px;
        }
      }
    }
  }

  .firstP {
    margin-top: 0px;
  }

  .secondP {
    margin-top: 22px;
    line-height: 32px;
  }

  .thirdP {
    max-width: 656px;
    margin: 0px auto;
    color: #4377b3;
    margin-top: 20px;
    line-height: 38px;
  }

  .startButton {
    margin: 0px auto;
    display: block;
    padding: 20px 60px;
    background-color: #084571;
    color: #ffffff;
    line-height: 20px;
    border: none;
    border-radius: 5px;
    margin-top: 24px;
    margin-bottom: 54px;
    display: inline-block;
    text-decoration: none;
  }

  .uneventful {
    background-color: #145f95;
    color: #fff;
    padding: 64px 0px 74px;

    h3 {
      line-height: 40px;
      margin-bottom: 38px;
      letter-spacing: 1.5px;
    }

    h5 {
      max-width: 1200px;
      line-height: 40px;
    }
  }
}

@media screen and (max-width: 960px) {
  .easy {
    .easyCard {
      transform: translateY(-32px);
      padding: 0px 20px;

      h3 {
        font-size: 22px;
        padding-top: 24px;
      }

      h5 {
        font-size: 16px;
        line-height: 32px;
        padding-top: 12px;
      }

      .stepsWrpr {
        grid-template-columns: 1fr;

        .stepWrpr {
          margin-bottom: 70px;

          img {
            scale: 0.8;
          }
          .cntnt {
            margin-top: 20px;
          }

          &:after {
            display: none;
          }
        }
      }
    }

    .firstP {
      font-size: 18px;
    }

    .secondP {
      font-size: 16px;
      line-height: 30px;
      margin-top: 14px;
    }

    .thirdP {
      font-size: 16px;
      line-height: 26px;
      margin-top: 10px;
    }

    .startButton {
      margin-bottom: 36px;
      font-size: 14px;
      padding: 14px 50px;
    }

    .uneventful {
      padding: 48px 0px 58px;

      h3 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 20px;
        line-height: 34px;
      }

      h5 {
        font-size: 16px;
        line-height: 34px;
      }
    }
  }
}
