.serviceLanding {
  padding: 72px 0px 54px;
  img {
    width: 50%;
    max-width: 664px;
  }

  .content {
    width: 50%;
    padding-left: 86px;

    h3 {
      max-width: 344px;
      margin-bottom: 28px;
      line-height: 46px;
    }

    p {
      max-width: 444px;
      line-height: 32px;
    }
  }
}

@media screen and (max-width: 960px) {
  .serviceLanding {
    .contentWrpr {
      flex-flow: column;

      img {
        width: 100%;
      }

      .content {
        width: 100%;
        padding-left: 0px;

        h3 {
          font-size: 18px;
          text-align: center;
          margin: 0px auto;
        }

        p {
          font-size: 16px;
          text-align: center;
          line-height: 28px;
          margin: 0px auto;
        }
      }
    }
  }
}
