.autoSuggestions {
  color: #04395f;
  padding-top: 114px;
  padding-bottom: 180px;

  h3 {
    line-height: 38px;
  }

  p {
    line-height: 42px;
    max-width: 1092px;
    margin: 14px auto 0px;
  }
}

@media screen and (max-width: 960px) {
  .autoSuggestions {
    color: #04395f;
    padding-top: 84px;
    padding-bottom: 96px;

    h3 {
      line-height: 34px;
      font-size: 24px;
    }

    p {
      line-height: 32px;
      max-width: 1092px;
      margin: 14px auto 0px;
      font-size: 16px;
    }
  }
}
