.how {
  .hdr {
    height: 254px;
    padding-bottom: 50px;

    .sectionsWrpr {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 36px;
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0px;
      translate: 0% 50%;
      z-index: 1;
      margin: 0px 20px;

      .sec {
        text-align: center;
        height: 116px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .ftr {
    padding: 44px 0px;
    margin-top: 100px;
  }
}

@media screen and (max-width: 960px) {
  // text-align: center;
  // height: 116px;
  // border-radius: 5px;
  // cursor: pointer;
  // font-size: 15px;
  // border-radius: 0px;
  // border-right: 1px solid #ffffff75;
  // height: 64px;
  // font-weight: 700;

  .how {
    .hdr {
      .sectionsWrpr {
        width: 100%;
        margin: 0px;
        column-gap: 0px;

        .sec {
          font-size: 15px;
          border-radius: 0px;
          border-right: 1px solid #ffffff75;
          height: 64px;
          font-weight: 700;

          &:nth-last-child(1) {
            border-right: none;
          }
        }
      }
    }
  }
  .ftr {
    padding: 13px 40px;
    font-size: 14px;
  }
}
