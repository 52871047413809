.landing {
  background-color: #084571;

  .content {
    padding-left: 38px;

    h2 {
      color: #fcf0e6;
      padding-top: 156px;
      line-height: 115px;
    }

    p {
      color: #fcf0e6;
      max-width: 560px;
      line-height: 42px;
      margin-top: 32px;
    }
  }

  .imgSec {
    width: 50%;

    .imgWrpr {
      background-image: url("../../assets/imgs/landing-img.png");
      height: 504px;
      width: 415px;
      background-size: 100%;
    }
  }

  .buttonsWrpr {
    margin-top: 86px;
    margin-bottom: 158px;

    .button {
      text-decoration: none;
      background-color: #084571;
      color: #fcf0e6;
      padding: 9px 19px;
      border: 1px solid #fff9f4;
      line-height: 20px;
      border-radius: 4px;
    }

    .started {
      background-color: #fff9f4;
      color: #084571;
      margin-right: 22px;
    }
  }
}

@media screen and (max-width: 960px) {
  .landing {
    .contentWrpr {
      flex-flow: column;
      padding-bottom: 60px;

      .content {
        padding-left: 0px;
        width: 100%;

        h2 {
          padding-top: 40px;
          font-size: 42px;
          text-align: center;
          line-height: 100px;
        }

        p {
          font-size: 18px;
          text-align: center;
          max-width: 300px;
          margin: 0px auto;
          line-height: 34px;
        }

        .buttonsWrpr {
          justify-content: center;
          margin: 40px 0px 60px;

          .button {
            max-width: 144px;
            width: 50%;
            text-align: center;

            .started {
              // marri
            }
          }
        }
      }

      .imgSec {
        width: 100%;

        .imgWrpr {
          background-repeat: no-repeat;
          aspect-ratio: 1/1;
          width: 70%;
          height: initial;
        }
      }
    }
  }
}
