.modal{
    max-width: 800px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #084571;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    @media screen {
        width: 92% !important;
        margin: auto !important;
    }


    .leftSide{
        background: linear-gradient(#E7EEF8, #E7EEF8);
        border-radius: 8px 0 0 8px;
        position: relative;

        @media screen and (max-width: 525px) {
            border-radius: 8px 8px 0 0;

            img{
                width: 50% !important;
            }
        }

        @media (min-width: 525px) and (max-width: 767px) {
            border-radius: 8px 8px 0 0;
            img{
                width: 35% !important;
            }
        }

        .intro{
            color: #F2761C;
            line-height: 28px;
            @media screen and (max-width: 767px) {
                font-size: 18px;
            }
        }
        .connect{
            color: #084571;
            @media screen and (max-width: 767px) {
                font-size: 12px;
            }
        }

        .close{
            display: none;

            @media screen and (max-width: 767px) {
                display: block;
                outline: none;
                border: none;
                background-color: transparent;
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }
       


    }
    .rightSide{
        background-color: #084571;
        position: relative;
        padding: 30px 40px;
        border-radius: 0 8px 8px 0;

        .innerRight{
            width: 74%;
        }

        @media screen and (max-width: 767px) {
            padding: 20px 30px;
            border-radius: 0 0 8px 8px;

            .innerRight{
                width: 100%;
            }

            h4{
                font-size: 16px;
                
            }
            p{
                // display: none;
                font-size: 12px;
                line-height: 20px !important;
                margin-bottom: 10px;
            }
            .close{
                display: none;
            }
            .free{
                display: block;
                margin-top: 20px;
                padding-top: 20px;
            }
        }

        h4.text{
            letter-spacing: 2px;
        }
        p.text{
            letter-spacing: 0px;
        }
        
        .text{
            line-height: 28px;
        }
        .upgrade{
            background-color: #3170CF;
            outline: none;
            border: none;
            color: #FFFCFA;
            padding: 8px 12px;
            border-radius: 4px;
            line-height: 24px;
            text-decoration: none;
            @media screen and (max-width: 767px) {
                font-size: 14px;
            }
        }
        .free{
            outline: none;
            border: none;
            background-color: transparent;
            color: #FFFFFF;
            line-height: 24px;
            margin: 0 16px;
            text-decoration: none;
            @media screen and (max-width: 767px) {
                font-size: 14px;
            }
        }
        .close{
            outline: none;
            border: none;
            background-color: transparent;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999; 
}