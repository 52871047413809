.heading{
    color: #084571;
    line-height: 54px;
}
.firstP{
    color: #848199;
}

.btnGrp{
    background-color: #ffffff;
    color: #084571;
    cursor: pointer;

    .active{
        background-color: #084571;
        color: #ffffff;
    }
}

.card{
    position: relative;
    border-radius: 12px !important;

    .bestValue{
        position: absolute;
        letter-spacing: 0.83px;
        color: #FFFFFF;
        background-color: #F2761C;
        border: none;
        outline: none;
        right: 6%;
        border-radius: 30px;
        padding: 6px 20px;
        font-size: 10px;
    }

    .cardH4{
      padding-top: 20px;
      color: #084571;
      line-height: 33.5px;
      margin-bottom: 0;
    }
    .cardP{
        color: #849AB9;
        line-height: 14.4px;
    }
    .cardH5{
        color: #3170CF;
        line-height: 30.26px;
        margin-bottom: 0;

        .month{
            color: #174A97C4;
            line-height: 19.36px;
        }
    }
    .promo{
        color: #3170CF;
        line-height: 14.52px;

    }
    .cardBtn{
        background-color: #E7EEF8;
        color: #084571;
        text-decoration: none;
        text-align: center;
    }
    .cardH6{
        color: #145F95;
        line-height: 22.5px;
        letter-spacing: 1px;
    }
    ul{ 

        .list{
            color: #145F95;
            line-height: 22.5px;
            letter-spacing: 1px;

            &::before {
                content: '';
                position: absolute;
                left: -30px;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
                height: 20px; 
                background: url('../../../../assets/imgs/check-circle.svg') no-repeat center center;
                background-size: contain;
              }
        }
    }
}