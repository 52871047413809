.simple {
  background-color: #04395f;

  .listWrpr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 26px;
    transform: translateY(-84px);

    .sec {
      background-color: #fff9f4;
      max-width: 252px;
      border-radius: 12px;
      color: #174a97;
      line-height: 32px;
      padding: 0px 14px 28px;
      border: 1px solid #e4e4e4;

      .pill {
        height: 54px;
        width: 90%;
        max-width: 182px;
        background-color: #145f95;
        border-radius: 50px;
        margin: 0px auto;
        transform: translateY(-50%);
        padding: 0px 40px;
        color: #f6f8ff;

        img {
          position: absolute;
          right: 12px;
          top: 13px;
        }
      }

      h5 {
      }

      p {
        max-width: 176px;
        margin: 0px auto;
      }

      &:nth-last-child(1) {
        p {
          max-width: 196px;
        }
      }
    }
  }

  h3 {
    color: #fff;
    letter-spacing: 1.5px;
  }

  .text {
    line-height: 32px;
    color: #fff;
    max-width: 1056px;
    margin: 0px auto;
    margin-top: 24px;
  }

  a {
    line-height: 20px;
    color: #fff;
    background-color: #548ed1;
    padding: 24px 62px;
    margin: 0px auto;
    display: block;
    border-radius: 5px;
    margin-top: 36px;
    border: none;
    text-decoration: none;
  }

  .bgImg {
    margin: 0px auto;
    display: block;
    max-width: 100%;
    transform: translateY(30%);
    z-index: 1;
    margin-top: -90px;
  }
}

@media screen and (max-width: 960px) {
  .simple {
    .listWrpr {
      display: block;
      transform: none;
      padding-top: 60px;

      .sec {
        margin: 0px auto 50px;
      }
    }

    h3 {
      font-size: 22px;
      line-height: 34px;
    }

    .text {
      font-size: 16px;
      line-height: 26px;
    }

    .bgImg {
      margin-top: 0px;
    }
  }
}
