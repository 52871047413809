.feedback {
  padding: 150px 0px 0px;
}

.mobile {
  display: none;
}

.swiperWrpr {
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 123px;
    width: 168px;
    background-image: url("../../assets/imgs/quote-2.png");
    z-index: 2;
    transform: translate(-32px, -88px);
    background-repeat: no-repeat;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 123px;
    width: 168px;
    z-index: 2;
    transform: translate(32px, -36px);
    background-image: url("../../assets/imgs/quote-1.png");
    background-repeat: no-repeat;
  }
}

.slide {
  background-color: #e7eef8;
  border-radius: 18px;
  //   height: 384px;
  // width: 100%;
  min-height: 100%;
  margin: 0px 10px;
  padding: 52px 42px 24px;

  .content {
    line-height: 44px;
    color: #3879b7;
    margin-bottom: 22px;
  }

  .sigWrpr {
    margin-top: auto;
    line-height: 30px;
    color: #3879b7;
  }
}

.newsLetter {
  background-color: #f9fafb;
  margin-top: 75px;
  padding: 96px 112px;
  margin-bottom: 78px;

  h5 {
    line-height: 44px;
    color: #101828;
  }

  .sub {
    line-height: 30px;
    margin-top: 20px;
    color: #475467;
  }

  .inputSec {
    width: 100%;
    max-width: 496px;

    input {
      width: 360px;
      border: 1px solid #d0d5dd;
      padding: 11px 14px;
      border-radius: 8px;
      line-height: 24px;
      color: #667085;
      margin-right: 16px;
    }

    button {
      width: 120px;
      padding: 12px 20px;
      border-radius: 8px;
      line-height: 24px;
      border: none;
      background-color: #084571;
      color: #fff;

      &:disabled {
        cursor: no-drop;
        opacity: 0.5;
      }
    }
  }
}

.letUs {
  background-color: #145f95;
  color: #fff;
  padding: 45px 45px;
  line-height: 28px;
}

@media screen and (max-width: 960px) {
  .feedback {
    padding: 80px 0px 0px;
  }
  .mobile {
    display: block;

    .slide {
      .content {
        font-size: 16px;
        line-height: 26px;
      }

      .sigWrpr {
        p {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }

  .desktop {
    display: none;
  }

  .swiperWrpr {
    &::before {
      scale: 0.6;
      translate: -20px -14px;
    }
    &::after {
      scale: 0.6;
      translate: 20px -10px;
    }
  }

  .newsLetter {
    flex-flow: column;
    padding: 20px 20px;
    margin-bottom: 20px;
    border-radius: 5px;

    h5 {
      font-size: 24px;
    }

    p.sub {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 8px;
    }

    .inputSec {
      width: 100%;

      input {
        width: 70%;
      }
    }
  }

  .letUs {
    font-size: 14px;
    padding: 13px 40px;
  }
}
