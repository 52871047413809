.genvite {
  padding-top: 230px;

  .landing {
    justify-content: space-around;
    padding: 0px 6% 86px;
    border-bottom: 1px solid #4377b3;

    .sec {
      line-height: 32px;
      width: 54%;

      h3 {
        line-height: 36px;
        margin-bottom: 40px;
      }

      &:nth-child(1) {
        width: 46%;
        padding-right: 8%;
      }

      img {
        max-width: 100%;
        // margin-left: auto;
      }

      &.imgSec {
        .videoTitle {
          color: #ffffff;
          position: absolute;
          top: 90px;
          left: 0px;
          z-index: 1;
          height: 100%;
          width: 100%;
          // background-color: #ffffff62;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 70px;
          text-shadow: 3px 3px 3px rgb(20, 20, 20);
          opacity: 0;
          transition: all 0.2s;
        }

        &:hover {
          .videoTitle {
            opacity: 1;
            top: 0px;
          }
        }
      }

      p {
        &:nth-last-child(1) {
          margin-top: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .genvite {
    padding-top: 92px;
    .landing {
      flex-flow: column;

      .sec {
        &:nth-child(1) {
          width: 100%;

          h3 {
            font-size: 24px;
            text-align: center;
          }

          p {
            font-size: 18px;
            text-align: center;
          }
        }
      }

      .imgSec {
        width: 100%;
        margin-top: 28px;

        .videoTitle {
          font-size: 18px;
        }
      }
    }
  }
}
