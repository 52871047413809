.storyLanding {
  padding: 94px 0px 100px;

  h2 {
    line-height: 26px;
    margin-bottom: 88px;
  }

  p {
    line-height: 32px;
    margin: 0px auto 26px;
    max-width: 1043px;
  }
}

@media screen and (max-width: 960px) {
  .storyLanding {
    padding: 54px 0px 30px;

    h2 {
      font-size: 26px;
      margin-bottom: 42px;
    }

    p {
      line-height: 26px;
      margin: 0px auto 26px;
      font-size: 16px;
      max-width: 300px;
    }
  }
}
