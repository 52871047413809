.contentWrpr {
  padding: 140px 0px 300px;
  .innerWrpr {
    border: 1px solid #f6f6f6;
    border-radius: 6px;
    box-shadow: 0px 2px 6px -2px #00000040;
    padding: 100px 58px;
    margin: 0px 13px;

    .iconWrpr {
      height: 118px;
      width: 118px;
      border-radius: 100px;
      border: 4px solid #f6f6f6;
      background-color: #fff;
      translate: 0% -50%;
      left: 56px;
      position: absolute;
      top: 0px;
    }

    .sec {
      h3 {
        margin-bottom: 8px;
        line-height: 42px;
      }

      h5 {
        margin-bottom: 44px;
      }
      .label {
        line-height: 18px;
        margin-bottom: 5px;
      }

      .val {
        line-height: 14px;
        margin-bottom: 28px;
      }

      &:nth-last-child(1) {
        width: 54%;
        padding-right: 0px;

        .line {
          margin-bottom: 28px;

          input,
          textarea {
            width: 100%;
            height: 52px;
            border: 1px solid #4377b3;
            border-radius: 4px;
            padding: 0px 15px;

            &::placeholder {
              color: #4377b3;
            }
          }

          textarea {
            height: 136px;
            resize: none;
            padding: 18px 15px;
          }

          &.multi {
            input {
              width: 50%;

              &:nth-child(1) {
                margin-right: 26px;
              }
            }
          }
        }

        button {
          width: 100%;
          height: 52px;
          border: none;

          &:disabled {
            cursor: no-drop;
            opacity: 0.5;
          }
        }
      }
      width: 46%;
      padding-right: 40px;
    }
  }
}

@media screen and (max-width: 960px) {
  .contentWrpr {
    padding: 70px 0px 70px;

    .innerWrpr {
      padding: 52px 24px 30px 24px;
      flex-flow: column;

      .iconWrpr {
        scale: 0.65;
        left: 0px;
      }

      .sec {
        width: 100%;
        padding-right: 0px;

        .val {
          margin-bottom: 12px;
          line-height: 18px;
          font-size: 14px;
        }

        &:nth-last-child(1) {
          width: 100%;
          margin-top: 26px;

          .line {
            &.multi {
              flex-flow: column;
              input {
                width: 100%;

                &:nth-child(1) {
                  margin-right: 0px;
                  margin-bottom: 28px;
                }
              }
            }
          }
        }

        h3 {
          font-size: 22px;
        }

        h5 {
          font-size: 18px;
        }
      }
    }
  }
}
