.header {
  background-color: #04395f;
  height: 86px;
  color: #fcf0e6;
  position: sticky;
  top: 0px;
  z-index: 111;

  .contentWrpr {
    height: 100%;
  }

  .navs {
    height: 100%;

    .nav {
      color: #fcf0e6;
      padding: 0px 22px;
      text-decoration: none;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;

      &:after {
        content: "";
        border: 2px solid transparent;
        border-width: 3px 0px 0px 0px;
        position: absolute;
        bottom: 0px;
        width: 100%;
        border-radius: 10px 10px 0px 0px;
        transition: all 0.25s;
      }

      &.active {
        // background-color: green;

        &:after {
          border-color: #fcf0e6;
        }
      }
    }

    .clearButton {
      border: 1px solid #fcf0e6;
      color: #fcf0e6;
      padding: 10px 20px;
      border-radius: 4px;
      margin-right: 28px;
      margin-left: 6px;
      height: auto;
    }

    .fullButton {
      background-color: #fcf0e6;
      color: #003559;
      padding: 10px 20px;
      border-radius: 4px;
      margin-right: 28px;
      margin-left: 6px;
      height: auto;
    }
  }
}

@media screen and (max-width: 1282px) {
  .header {
    .mobileOpener {
      height: 40px;
      width: 40px;
      cursor: pointer;
    }

    .opener {
      height: 3px;
      width: 30px;
      background-color: #fff;

      &::before,
      &::after {
        content: "";
        display: block;
        background-color: #fff;
        right: 0px;
        top: 0px;
        height: 3px;
        width: 100%;
      }

      &::before {
        translate: 0px -10px;
        transition: rotate 0.2s;
      }

      &::after {
        translate: 0px 7px;
        transition: rotate 0.35s;
      }

      &.open {
        background-color: transparent;
        height: 0px;

        &::before {
          rotate: 45deg;
          translate: 0px 0px;
        }

        &::after {
          rotate: -45deg;
          translate: 0px -3px;
        }
      }
    }
    img {
      scale: 0.85;
    }

    .navs {
      position: fixed;
      top: 87px;
      left: 0px;
      background: #0f3a60;
      height: calc(100% - 86px);
      width: 100%;
      z-index: 1;
      flex-flow: column;
      overflow: auto;
      padding: 0px 24px;
      display: none;

      &.showMobile {
        display: flex;
      }

      .nav {
        font-size: 18px;
        height: 66px;
        width: 100%;

        &.active {
          // background-color: green;

          &:after {
            border-color: #fcf0e600;
          }
        }
      }

      .links {
        padding: 0px 24px 24px;
        margin-top: auto;
        width: 100%;
        justify-content: center;

        a.nav {
          width: 50%;
          font-size: 15px;
          padding: 0px 0px;
          white-space: nowrap;
          margin: 0px;
          height: 52px;
          max-width: 200px;

          &:nth-child(1) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}
