.roster {
  .listWrpr {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 12px;

    .listItem {
      background-color: #084571;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      padding: 34px 34px 30px;
      color: #fff;
      border-radius: 6px;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 58px;
      }

      p {
        margin-top: 10px;

        span {
          position: absolute;
          left: 50%;
          bottom: -6px;
          translate: -50% 100%;
          font-size: 9px;
          font-weight: 400;
          white-space: nowrap;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .roster {
    .listWrpr {
      grid-template-columns: 1fr 1fr;
      row-gap: 12px;
    }
  }
}
